.itemLabel {
  padding: 8px 0;
  line-height: 16px;
  min-width: 160px !important;
  width: 160px !important;
}
.el-checkbox-group {
  position: relative;
  top: 3px;
}
.operationControlReset > div {
  line-height: 32px;
}
 /deep/ .operationControlReset .el-checkbox__inner {
  min-width: 15px;
  text-align: right;
  font-size: 14px;
  padding: 0;
  border-color: #5c6be8;
}
 /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
 /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #5c6be8;
}
 /deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #5c6be8;
  border-color: #5c6be8;
}
 /deep/ .el-table__empty-block {
  min-height: 500px;
}
.searchbox {
  width: 100%;
  justify-content: flex-start;
}
.spInput /deep/ .el-input {
  width: 260px;
}
.spInput /deep/ .el-input__suffix-inner {
  width: auto;
  min-width: unset;
}
.spInput /deep/ .el-input__count {
  width: auto;
  min-width: unset;
}
.spInput /deep/ .el-input__count .el-input__count-inner {
  width: auto;
  min-width: unset;
}
.spInput .spInputEx {
  min-width: 400px;
  text-align: left;
}
